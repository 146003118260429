$exin-green: #BDCD00;
$exin-lightblue: #0289CD;
$exin-blue: #015794;
$exin-darkblue: #003255;

$exin-light-alt: #BCC4F3;
$exin-light-alt2: #B0BAF3;
$exin-dark-alt: #27448f;

/* customize variables */
$primary: $exin-light-alt;
$secondary: $exin-dark-alt;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

// customize classes
.nav-tabs {
  .nav-link {
    color: #0289CD;
  }
  .nav-link.active {
    color: #003255;
  }
}

.container {
  max-width: 1750px;
}

// Input validation icons
.validation-icon-wrapper {
  position: relative;
}
.is-valid, .is-invalid, .was-validated .form-control, .was-validated .form-select {
  background-image: none !important;
  padding-right: 0.75rem !important;
}

.form-control-icon {
  position: absolute;
  right: -0.2rem;
  bottom: -0.2rem;
  visibility: hidden;
  background: white;
  border-radius: 100%;
  z-index: 3;
}

.is-valid ~ .validation-icon-wrapper > .if-valid,
.was-validated .form-control:valid + .validation-icon-wrapper > .if-valid,
.was-validated .form-select:valid + .validation-icon-wrapper > .if-valid {
  color: var(--bs-success);
  visibility: visible;
}

.is-invalid ~ .validation-icon-wrapper > .if-invalid,
.was-validated .form-control:invalid + .validation-icon-wrapper > .if-invalid,
.was-validated .form-select:invalid + .validation-icon-wrapper > .if-invalid {
  color: var(--bs-danger);
  visibility: visible;
}

.workload-important {
  * {
    background-color: $exin-green !important;
    border-color: darken($exin-green, 10%) !important;
    font-weight: bold;
  }
}